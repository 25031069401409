import { apiUrl } from '../../../settings'

export const getUsersUrl = () => {
  return apiUrl + '/users'
}

export const updateAdminUserUrl = () => {
  return apiUrl + '/updateAdminUser'
}
export const getArticleActivitiesUrl = () => {
  return apiUrl + '/articleActivityForAdminUsers'
}

export const getFeedbacksUrl = () => {
  return apiUrl + '/allFeedBacks'
}
