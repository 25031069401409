import {
    Button,
    Card,
    Col,
    Collapse,
    Form,
    Icon,
    Input,
    notification,
    Popconfirm,
    Row,
    Select,
    Table,
    Tag,
    Tooltip
} from 'antd'
import CollapsePanel from 'antd/lib/collapse/CollapsePanel'
import async from 'async'
import _ from 'lodash'
import moment from 'moment'
import React, {PureComponent} from 'react'
import ReactHtmlParser from 'react-html-parser'
import {connect} from 'react-redux'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import GetEachFormFields from '../../components/_utils/appFormUtils'
import {
    CkEditor2013, CustomCkEditor, getFigureContent,
    InputBox,
    mentionsFeeds
} from '../../components/_utils/appUtils'
import {getUrlParams} from '../../routes'
import {AddBlogFxn, singleArticle} from '../article/actions'
import {searchKeyword} from './actions'
import AddAffiliate from './addAffiliate'
import {default as AddInformation} from './addInformation'
import AddKeyword from './addKeywordDrawer'
import AddTable from './addTable'
import EditAffiliate from './editAffiliate'
import EditInformation from './editInformation'
import EditTable from './editTable'

const {Option} = Select
const $ = window.$
const tableDataArr = [
    {name: 'table', label: 'Tables'},
    {name: 'figure', label: 'Figures'},
    {name: 'supplementaryTable', label: 'Supplementary Tables'},
]

@Form.create()
class AddBlog extends PureComponent {
    state = {
        fileKey: moment(),
        ckeditorKey: moment() + 1,
        infoData: [],
        affiliationData: [],
        keywords: [],
        featuredImage: '',
        inputVisible: false,
        inputValue: '',
        loop: null,
        page: 1,
        valGet: false,
        editTable: {},
        editInfo: {},
        keywordList: [],
        editVal: {},
        indexVal1: null,
        table: [],
        figure: [],
        supplementaryTable: [],
        plate: [],
        tableDrawerState: {
            visible: false,
            tableType: '',
            tableTitle: ''
        },
        superScriptList: [],
        currentStatus: ''
    }
    keywordEvents = {
        getKeyword: async value => {
            if (value) {
                let {data} = await searchKeyword({keyword: value})
                if (data) {
                    this.setState({keywordList: data})
                }
            }
        },
        chooseKeyword: value => {
            let clData = _.clone(this.state.keywords)
            let clDataList = _.clone(this.state.keywordList)
            let findData = _.find(clDataList, item => {
                return item._id == value.key
            })
            clData.push({
                keywordId: findData._id,
                keyword: findData.keyword,
                slugKeyword: findData.slugKeyword
            })
            this.setState({
                keywords: clData,
                keywordList: []
            })
        },

        removeKeyword: id => {
            let clData = _.clone(this.state.keywords)
            clData = _.reject(clData, item => {
                return item.keywordId == id
            })
            this.setState({
                keywords: clData
            })
        }
    }

    events = {
        addData: data => {
            let cloneData = _.clone(this.state[data.tableType])
            cloneData.push(data)
            this.setState({
                [data.tableType]: cloneData
            })
        },
        showDrawer: data => {
            this.setState({
                tableDrawerState: {
                    visible: true,
                    tableType: data.name,
                    tableTitle: data.label
                }
            })
        },

        hideDrawer: () => {
            this.setState({
                tableDrawerState: {
                    visible: false,
                    tableType: '',
                    tableTitle: ''
                }
            })
        },
        deleteItem: (index, tableType) => {
            let cloneData = _.clone(this.state[tableType])
            cloneData = _.reject(cloneData, (item, key) => {
                return key == index
            })
            this.setState({
                [tableType]: cloneData
            })
        },
        onCloseKeyword: () => {
            this.setState({
                open4: false
            })
        },
        showKeywordDrawer: () => {
            this.setState({
                open4: true
            })
        }
    }

    constructor(props) {
        super(props)
    }

    stickyHeader = () => {
        $(window).scroll(function () {
            let classCheck = $('aside').hasClass('ant-layout-sider-collapsed')
                ? 'fixedColl'
                : 'fixed'
            if ($(window).scrollTop() >= 250) {
                $('.sticky-header').addClass(classCheck)
                $('.headerPanel').addClass('sticky-header-bg')
            } else {
                $('.sticky-header').removeClass('fixedColl fixed')
                $('.headerPanel').removeClass('sticky-header-bg')
            }
        })
    }

    chooseDocument = e => {
        let {name, files} = e.target
        if (files && files.length) {
            this.setState({[name]: files[0]})
        }
    }

    onClose = () => {
        this.setState({
            ['open']: false
        })
    }

    handleDelete = key => {
        const newData = this.state.infoData.filter(item => item.menuHeading !== key)
        this.setState({infoData: newData})
    }

    handleSubmit = async e => {
        const {dispatch, form} = this.props;
        let {setFieldsValue} = form;
        e.preventDefault()

        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let {
                    infoData,
                    keywords,
                    description,
                    featuredImage,
                    // articleId,
                    currentStatus
                } = this.state


                if (description === '') {
                    notification.error({
                        message: 'Required',
                        description: 'Enter Blog Description'
                    })
                    return
                }
                // if (!keywords || (keywords && !keywords.length)) {
                //   notification.error({
                //     // message: 'Required',
                //     description: 'Enter Blog Keywords'
                //   })
                //   return
                // }

                let fd = await new FormData()
                let obj = {
                    ...valData,
                    // articleId,
                    infoData,
                    blogKeywordList: keywords,
                    description,
                }
                if (featuredImage && featuredImage.name) {
                    fd.append('featuredImage', featuredImage)
                }
                async.each(
                    tableDataArr,
                    (item, cb1) => {
                        if (this.state[item.name] && this.state[item.name].length) {
                            obj[item.name] = this.state[item.name]
                            _.each(this.state[item.name], (item1, key) => {
                                if (item1.image && item1.image.name) {
                                    fd.append(`${item.name}Images`, item1.image)
                                }
                            })
                            cb1()
                        } else {
                            obj[item.name] = []
                            cb1()
                        }
                    },
                    async () => {
                        fd.append('obj', JSON.stringify(obj))
                        let resp = await dispatch(AddBlogFxn(fd))
                        if (resp && resp.success) {

                            setFieldsValue({
                                date: undefined,
                                metaTitle: undefined,
                                metaDescription: undefined,
                                metaKeywords: undefined,
                            })
                            this.setState({
                                supplementaryTable: [],
                                figure: [],
                                table: [],
                                keywords: [],
                                featuredImage: {},
                                title: undefined,
                                description: undefined,
                                fileKey: moment()
                            })

                            notification.success({
                                message: resp.message
                            })
                        }
                    }
                )
            }
        })
    }

    showDrawer = () => {
        this.setState({
            ['open']: true
        })
    }

    componentDidMount() {
        this.stickyHeader()
        this.loadArticleData()
    }

    loadArticleData = () => {
        /*   let data = getUrlParams('article.addBlog', this.props.pathname)
           if (!!data) {
             let { id } = data
             this.setFormValuesFxn(id)
           }*/
    }

    editTableEvents = {
        showDrawerEditTable: (item, tableType) => {
            let {articleId} = this.state
            this.setState({
                editTable: {...item, tableType, articleId},
                openTable: true
            })
        },
        hideDrawerEditTable: () => {
            this.setState({
                editTable: {},
                openTable: false
            }, () => {
                this.loadArticleData()
            })
        }
    }

    render() {
        // }

        const {
            form: {getFieldDecorator, getFieldValue, setFieldsValue, featuredImage},
        } = this.props
        const {
            oldFeatureImage,
            tableDrawerState,
        } = this.state

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 18},
                md: {span: 14}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 24},
                md: {span: 24}
            }
        }

        const inputSchema = {
            fields: [
                {
                    label: 'Date',
                    key: 'date',
                    required: true,
                    placeholder: 'Date',
                    type: 'date',
                    span: 8
                },
                {
                    label: 'Featured Image',
                    key: 'featuredImage',
                    span: 8,
                    type: 'file',
                    customField: (
                        <InputBox title={`Featured Image`} className={'rowFlex'}>
                            <Input
                                width='100%'
                                type={'file'}
                                name={'featuredImage'}
                                id={'featuredImage'}
                                className={'form-control'}
                                key={this.state.fileKey}
                                onChange={e => {
                                    this.chooseDocument(e)
                                }}
                            />
                            {oldFeatureImage && oldFeatureImage.path ? (
                                <a
                                    className={'linkBtn'}
                                    target={'_blank'}
                                    href={oldFeatureImage.path}>
                                    <Icon type={'eye'}/>
                                </a>
                            ) : null}
                        </InputBox>
                    )
                },
                {
                    label: 'Title',
                    key: 'title',
                    span: 24,
                    type: "textarea",
                    placeholder: "Title"
                    /* customField: (
                         <CustomCkEditor
                             title={'Title'}
                             basic={true}
                             key={'title'}
                             height={100}
                             html={this.state.title}
                             onChange={data => {
                                 this.setState({
                                     title: data
                                 })
                             }}
                         />
                     )*/
                },
                {
                    label: 'Description',
                    key: 'description',
                    type: 'ckeditor',
                    span: 24,
                    customField: (
                        <CustomCkEditor
                            html={this.state.description}
                            title={'Description'}
                            key={'description'}
                            onChange={data => {
                                this.setState({
                                    description: data
                                })
                            }}
                            height={500}
                        />
                    )
                },
                {
                    label: 'Meta Title',
                    key: 'metaTitle',
                    type: 'textarea',
                    span: 24,
                },
                {
                    label: 'Meta Description',
                    key: 'metaDescription',
                    type: 'textarea',
                    span: 24,
                },
                {
                    label: 'Meta Keywords',
                    key: 'metaKeywords',
                    type: 'textarea',
                    span: 24,
                },
            ]
        }


        const getColumnField = tableType => {
            let tableColumn = [
                {
                    title: ' Image ',
                    name: 'image',
                    key: 'image',
                    dataIndex: 'image',
                    width: 200,
                    render: image => {
                        return <img src={image.path} style={{height: 80}}/>
                    }
                },
                {
                    title: 'Description',
                    name: 'description',
                    key: 'description',
                    dataIndex: 'description'
                },
                {
                    title: 'Action',
                    name: 'action',
                    key: 'action',
                    dataIndex: 'action',
                    width: 100,
                    render: (item, record, index) => {
                        return (
                            <>
                                {record && record._id ? <Tooltip
                                    title='Edit  information'>
                                    <Button style={{marginRight: 5}}
                                            shape='circle'
                                            onClick={e => {
                                                this.editTableEvents.showDrawerEditTable(record, tableType)
                                            }}
                                            icon={'edit'}
                                    />
                                </Tooltip> : null}
                                <Popconfirm
                                    title={`Are you Sure, You want to delete?`}
                                    onConfirm={e => {
                                        this.events.deleteItem(index, tableType)
                                    }}
                                    okText='Yes'
                                    cancelText='No'>
                                    <Button shape='circle' icon={'delete'}/>
                                </Popconfirm>
                            </>
                        )
                    }
                }
            ]
            return tableColumn
        }

        return (
            <PageHeaderWrapper
                className={'articleHeader'}
                title={
                    <div className={'sticky-header'}>
                        <div className={'headerPanel'}>
                            <div className='titleDiv'>
                                Add Blog
                            </div>
                        </div>
                    </div>
                }>
                <Card bordered={true} className={'doi-form'}>
                    <Form
                        onSubmit={this.handleSubmit}
                        hideRequiredMark
                        className={'inputForm'}
                        noValidate={true}>
                        <Row gutter={16}>
                            {inputSchema.fields.map((item, key) => {
                                return (
                                    !item.hidden && (
                                        <Col
                                            span={item.span ? item.span : 12}
                                            md={item.span ? item.span : 12}
                                            sm={12}
                                            xs={24}
                                            key={key}>
                                            {item.customField ? (
                                                item.customField
                                            ) : (
                                                <GetEachFormFields
                                                    item={item}
                                                    getFieldDecorator={getFieldDecorator}
                                                    formItemLayout={formItemLayout}
                                                />
                                            )}
                                        </Col>
                                    )
                                )
                            })}
                        </Row>

                        {this.state.open4 ? (
                            <AddKeyword
                                visible={this.state.open4}
                                onClose={() => {
                                    this.events.onCloseKeyword()
                                }}
                            />
                        ) : null}

                        <Card title={'Keywords'}>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item>
                                        <Select
                                            value={this.state.keywordList}
                                            showSearch={true}
                                            filterOption={false}
                                            labelInValue
                                            onChange={this.keywordEvents.chooseKeyword}
                                            onSearch={this.keywordEvents.getKeyword}
                                            name='keywords'
                                            placeholder=' Search Keywords'>
                                            {this.state.keywordList &&
                                                this.state.keywordList.map(item => (
                                                    <Option key={item._id}>{item.keyword}</Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                    <Button
                                        style={{marginTop: 3}}
                                        onClick={() => {
                                            this.events.showKeywordDrawer()
                                        }}
                                        className='ant-btn ant-btn-primary'>
                                        Add Keyword
                                    </Button>
                                </Col>
                                <Col span={24}>
                                    {this.state.keywords &&
                                        this.state.keywords.map((item, i) => {
                                            return (
                                                <>
                                                    <Tag>
                                                        {item.keyword}
                                                        <a
                                                            onClick={() => {
                                                                this.keywordEvents.removeKeyword(item.keywordId)
                                                            }}>
                                                            <Icon type={'close'}/>
                                                        </a>
                                                    </Tag>
                                                </>
                                            )
                                        })}
                                </Col>
                            </Row>
                        </Card>

                        {tableDataArr.map((item, key) => {
                            return (
                                <Card
                                    key={key}
                                    title={item.label}
                                    extra={
                                        <Form.Item
                                            name='addTable'
                                            style={{textAlign: 'right', marginTop: 8}}>
                                            <Button
                                                size='small'
                                                onClick={() => this.events.showDrawer(item)}
                                                className='ant-btn ant-btn-primary'>
                                                Add
                                            </Button>
                                        </Form.Item>
                                    }>
                                    <Col span={24} className={'custom_card'}>
                                        {this.state[item.name] && this.state[item.name].length ? (
                                            <Table
                                                className={'figureTable'}
                                                bordered={true}
                                                dataSource={this.state[item.name]}
                                                columns={getColumnField(item.name)}
                                                size={'small'}
                                                pagination={false}
                                            />
                                        ) : null}
                                    </Col>
                                </Card>
                            )
                        })}
                        <Form.Item style={{marginTop: 32}}>
                            <Button type='primary' htmlType='submit'>
                                SUBMIT
                            </Button>
                        </Form.Item>
                    </Form>

                    {tableDrawerState.visible ? (
                        <AddTable
                            title={tableDrawerState.tableTitle}
                            tableType={tableDrawerState.tableType}
                            visible={tableDrawerState.visible}
                            addTableData={this.events.addData}
                            onClose={() => {
                                this.events.hideDrawer()
                            }}
                        />
                    ) : null}

                    {/* edit Table */}
                    {this.state.editTable && this.state.openTable ? (
                        <EditTable
                            title={this.state.editTable.tableType}
                            visible={this.state.openTable}
                            editTable={this.state.editTable}
                            onClose={() => {
                                this.editTableEvents.hideDrawerEditTable()
                            }}
                        />
                    ) : null}


                </Card>
            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search,
    pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddBlog)
