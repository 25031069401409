import { notification } from 'antd'
import { hidePageLoad, showPageLoad } from '../../../modules/actions'

import { customAxios as axios, getToken } from '../../../request'
import {
  getArticleActivitiesUrl,
  getFeedbacksUrl,
  getUsersUrl,
  updateAdminUserUrl
} from '../apis'
export const getAllUsersFxn = filters => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...getToken()
  }
  let { data } = await axios.get(getUsersUrl(), config)
  dispatch(hidePageLoad())

  return data
}

export const updateAdminUser = valData => async dispatch => {
  console.log(valData, 'actiooon')
  dispatch(showPageLoad())
  let { data } = await axios.post(updateAdminUserUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }

  return data
}
export const getArticleActivities = filters => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...getToken()
  }
  let { data } = await axios.get(getArticleActivitiesUrl(), config)
  dispatch(hidePageLoad())

  return data
}
export const getFeedBacks = filters => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...getToken()
  }
  let { data } = await axios.get(getFeedbacksUrl(), config)
  dispatch(hidePageLoad())

  return data
}
