import {
  Badge,
  Button,
  Card,
  Icon,
  Input,
  notification,
  Switch,
  Table,
  Tooltip
} from 'antd'
import Color from 'color'
import update from 'immutability-helper'
import _ from 'lodash'
import memoizeOne from 'memoize-one'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormUtils, TableComp } from 'sz-react-utils-lite'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import Request from '../../../request'
import { getUrlPushWrapper } from '../../../routes'
import styles from './styles.less'
import { getAllUsersFxn } from '../actions'

const regExpFilters = ['name', 'local.email', 'userType']

class AllUsers extends Component {
  constructor(props) {
    super(props)
    this.table = React.createRef()
  }

  apiRequest = params => {
    let { dispatch } = this.props
    return new Promise(async resolve => {
      let data = await dispatch(
        getAllUsersFxn({
          ...params,
          regExFilters: regExpFilters
        })
      )
      resolve(data)
    })
  }

  deleteUser = params => {
    Request.deleteUserApi(params).then(data => {
      if (data.type) {
        notification.success({
          message: data.msg
        })
        this.reload()
      } else {
        notification.error({
          message: data.msg
        })
      }
    })
  }

  reload = () => {
    this.table.current.reload()
  }

  componentDidMount() {
  }

  render() {
    const columns = [
      {
        title: 'Name',
        key: 'name',
        sorter: true,
        dataIndex: 'name',
        searchTextName: 'name',
        filterByRegex: true,
        fixed: 'left',
        width: 130
      },
      {
        title: 'Email',
        dataIndex: 'local.email',
        key: 'local.email',
        filterByRegex: true,
        searchTextName: 'local.email',
        fixed: 'left',
        width: 150
      },
      {
        title: 'User Type',
        dataIndex: 'userType',
        key: 'userType',
        searchTextName: 'userType',
        filterByRegex: true
      },
      {
        title: 'Reviewer Id',
        dataIndex: 'reviewerId',
        key: 'reviewerId',
        searchTextName: 'userType',
        filterByRegex: true
      },
      {
        title: 'Password',
        dataIndex: 'displayPassword',
        key: 'displayPassword'
      },
      {
        title: 'Address',
        dataIndex: 'address',
        key: 'address'
      },
      {
        title: 'Institute',
        dataIndex: 'instituteAddress',
        key: 'instituteAddress'
      },
      {
        title: 'Designation',
        dataIndex: 'designation',
        key: 'designation'
      },
      {
        title: 'MobileNo',
        dataIndex: 'mobileNo',
        key: 'mobileNo'
      },

      {
        title: 'userName',
        dataIndex: 'userName',
        key: 'userName'
      },
      {
        title: 'Country',
        dataIndex: 'country',
        key: 'country'
      },
      {
        key: 'actions',
        width: 100,
        render: item => {
          return (
            <div>
              {item.userType == 'User' ? (
                <React.Fragment>
                  <Tooltip title='Show Details'>
                    <Button
                      className={styles.btn}
                      shape='circle'
                      type='danger'
                      onClick={() => {
                        this.deleteUser(item)
                      }}
                      icon='delete'
                    />
                  </Tooltip>
                </React.Fragment>
              ) : null}
            </div>
          )
        }
      }
    ]
    return (
      <PageHeaderWrapper title={'All Users'}>
        <Card bordered={true}>
          <TableComp
            ref={this.table}
            extraProps={{ scroll: { x: 1350 } }}
            columns={columns}
            apiRequest={this.apiRequest}
          />
        </Card>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllUsers)
