import {
    Button,
    Card,
    Popconfirm,
    Tooltip
} from 'antd'
import React, {useEffect, useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import {TableComp} from 'sz-react-utils-lite'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import {DefaultTablePagination, DisplayDate} from '../../components/_utils/appUtils'
import {blogListFxn, removeBlog} from './actions'
import {getPushPathWrapper} from '../../routes'
import BlogDetailDrawer from "./blogDetailDrawer";
import ReactHtmlParser from "react-html-parser";

const BlogComponent = props => {
    const [blogs, setBlogs] = useState([])
    let [state, setState] = useState({
        visible: false, blogId: ""
    })

    const dispatch = useDispatch()
    let [pageState, setPageState] = useState({
        page: 1,
        results: 0
    })
    const [total, setTotal] = useState(0)
    const tableRef = useRef()


    const handleDelete = async record => {
        let result = await dispatch(removeBlog(record))
        tableRef.current.reload()
    }

    const columns = [
        {
            title: 'Serial No.',
            dataIndex: 'orderNo',
            key: 'index',
            width: 100,
            render: (text, record, index) => {
                let currentIndex = parseInt(pageState.page - 1) * parseInt(pageState.results)
                return currentIndex + index + 1
            }
        },
        {
            title: 'Date',
            key: 'createdAt',
            dataIndex: 'createdAt',
            searchDateName: 'createdAt',
            width: 100,
            render: (item) => {
                return DisplayDate(item)
            }
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (val) => <div>
                {val ? ReactHtmlParser(val) : ""}
            </div>
        },

        {
            title: 'Action',
            key: 'operation',
            width: 150,
            render: (text, record) => (
                <div className='alignCenter'>
                    <Tooltip title="View Details">
                        <Button
                            ghost
                            shape='circle'
                            type='primary'
                            icon='eye'
                            onClick={() => {
                                events.openDetailsDrawer(record._id)
                            }}
                            style={{marginRight: 8}}
                        />
                    </Tooltip>
                    <Tooltip title="Edit Blog">
                        <Button
                            ghost
                            shape='circle'
                            type='primary'
                            icon='edit'
                            onClick={() => {
                                dispatch(
                                    getPushPathWrapper('blogs.editBlog', {id: record._id})
                                )
                            }}
                            style={{marginRight: 8}}
                        />
                    </Tooltip>
                    <Popconfirm
                        placement='topLeft'
                        title={`Are you sure you want to delete this blog?`}
                        onConfirm={() => handleDelete(record)}
                        okText='Yes'
                        cancelText='No'>
                        <Button ghost shape='circle' type='danger' icon='delete'/>
                    </Popconfirm>
                </div>
            )
        }

    ]

    const apiRequest = params => {
        return new Promise(async resolve => {
            let result = await dispatch(
                blogListFxn({
                    ...params,
                    sortField: 'date',
                    sortOrder: 'descend'
                })
            )
            setPageState({
                page: params.page ? params.page : 1,
                results: params.results
            })
            setTotal(result.total)
            resolve(result)
        })
    }

    const events = {
        openDetailsDrawer: (blogId) => {
            setState({
                visible: true, blogId
            })
        },
        closeDetailsDrawer: () => {
            setState({
                visible: false, blogId: ""
            })
        }
    }

    return (
        <PageHeaderWrapper title={`Blogs (Total :${total})`}>
            <Card bordered={true}>
                <TableComp
                    ref={tableRef}
                    sorter
                    size='small'
                    columns={columns}
                    pagination={{...DefaultTablePagination(), defaultPageSize: 50}}
                    apiRequest={apiRequest}
                />
            </Card>

            {state.visible ?
                <BlogDetailDrawer {...state} onClose={events.closeDetailsDrawer}/> : null
            }

        </PageHeaderWrapper>
    )
}

export default BlogComponent
