import {
  Card
} from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'

import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Countries,
  DefaultTablePagination
} from '../../../components/_utils/appUtils'
import { getAllUsersFxn } from '../actions'

const regExpFilters = [
  'local.email',
  'address',
  'instituteAddress',
  'instituteName',
  'mobileNo',
  'userName',
  'displayPassword'
]

class allInstitute extends Component {
  state = {
    authorData: []
  }

  constructor(props) {
    super(props)

    this.table = React.createRef()
  }

  loadAuthors = params => {
    return new Promise(async resolve => {
      let { dispatch } = this.props
      let obj = {
        ...params,
        userType: 'Institute'
      }
      let data = await dispatch(
        getAllUsersFxn({ ...obj, regExFilters: regExpFilters })
      )
      resolve(data)
    })
  }

  render() {
    const { dispatch } = this.props

    const columns = [
      {
        title: 'Institute ',
        key: 'instituteName',
        sorter: true,
        dataIndex: 'instituteName',
        searchTextName: 'instituteName',
        filterByRegex: true,
        fixed: 'left',
        width: 130
      },
      {
        title: 'Institute Address',
        key: 'instituteAddress',

        dataIndex: 'instituteAddress',
        searchTextName: 'instituteAddress',
        filterByRegex: true,
        fixed: 'left',
        width: 130
      },
      {
        title: 'Email',
        dataIndex: 'local.email',
        key: 'local.email',
        searchTextName: 'email',
        filterByRegex: true,
        fixed: 'left',
        width: 150
      },

      {
        title: 'Department',
        dataIndex: 'department',
        key: 'department',
        searchTextName: 'department',
        filterByRegex: true
      },
      {
        title: ' Postal Code',
        dataIndex: 'postalCode',
        key: 'postalCode',
        searchTextName: 'postalCode',
        filterByRegex: true
      },

      {
        title: 'Phone No',
        dataIndex: 'phoneNo',
        key: 'phoneNo',
        searchTextName: 'phoneNo',
        filterByRegex: true
      },

      {
        title: 'User Name',
        dataIndex: 'userName',
        key: 'userName',
        searchTextName: 'userName',
        filterByRegex: true
      },
      {
        title: 'Password',
        dataIndex: 'displayPassword',
        key: 'displayPassword',
        searchTextName: 'displayPassword',
        filterByRegex: true
      },
      {
        title: 'Country',
        dataIndex: 'country',
        key: 'country',
        filters: Countries.map(x => ({ text: x.name, value: x.name })),
        render: (item, record) => {
          return (
            <div>
              <p>{item}</p>
              <p>{record.correspondingAuthorAffiliation}</p>
            </div>
          )
        }
      }
    ]
    return (
      <PageHeaderWrapper title={'All Institutes'}>
        <Card bordered={true}>
          <TableComp
            ref={this.table}
            extraProps={{ scroll: { x: 1350 } }}
            columns={columns}
            apiRequest={this.loadAuthors}
          />
        </Card>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(allInstitute)
