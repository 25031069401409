import {
  Badge,
  Button,
  Card,
  Icon,
  Input,
  notification,
  Switch,
  Table,
  Tooltip
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { Countries } from '../../../components/_utils/appUtils'
import { getAllUsersFxn } from '../actions'
import Request from '../../../request'
import EditAuthors from "../editAuthors";

const regExpFilters = [
  'name',
  'local.email',
  'address',
  'instituteName',
  'mobileNo',
  'userName',
  'displayPassword',
  'postalCode',
  'department',
  'lastName'
]

class allAuthors extends Component {
  state = {
    authorData: []
  }

  constructor(props) {
    super(props)

    this.table = React.createRef()
  }

  loadAuthors = params => {
    let { dispatch } = this.props
    return new Promise(async resolve => {
      let data = await dispatch(
        getAllUsersFxn({
          ...params, userType: 'Author',
          regExFilters: regExpFilters
        })
      )
      resolve(data)
    })
  }

  componentDidMount() {
  }

  showEditAdmin = (val, index) => {
    this.setState({open: true, editVal: val, indexVal1: index})
  }

  render() {
    const {dispatch} = this.props

    const columns = [
      {
        title: 'Name',
        key: 'name',
        sorter: true,
        dataIndex: 'name',
        searchTextName: 'name',
        filterByRegex: true,
        fixed: 'left',
        width: 120
      },
      {
        title: ' Last Name',
        key: 'lastName',
        sorter: true,
        dataIndex: 'lastName',
        searchTextName: 'lastName',
        filterByRegex: true,
        fixed: 'left',
        width: 120
      },
      {
        title: 'UserType',
        key: 'userType',
        sorter: true,
        dataIndex: 'userType',
        searchTextName: 'userType'
      },
      {
        title: 'Email',
        dataIndex: 'local.email',
        key: 'local.email',
        searchTextName: 'email',
        filterByRegex: true,
        fixed: 'left',
        width: 150
      },

      {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        searchTextName: 'address',
        filterByRegex: true
      },
      {
        title: ' Institute Name',
        dataIndex: 'instituteName',
        key: 'instituteName',
        searchTextName: 'instituteName',
        filterByRegex: true
      },
      {
        title: 'Job Title/Designation',
        dataIndex: 'designation',
        key: 'designation',
        searchTextName: 'designation',
        filterByRegex: true
      },
      {
        title: 'Department',
        dataIndex: 'department',
        key: 'department',
        searchTextName: 'department',
        filterByRegex: true
      },
      {
        title: ' Postal Code',
        dataIndex: 'postalCode',
        key: 'postalCode',
        searchTextName: 'postalCode',
        filterByRegex: true
      },
      {
        title: 'Password',
        dataIndex: 'displayPassword',
        key: 'displayPassword',
        searchTextName: 'displayPassword',
        filterByRegex: true
      },
      {
        title: 'Country',
        dataIndex: 'country',
        key: 'country',
        filters: Countries.map(x => ({text: x.name, value: x.name})),
        render: (item, record) => {
          return (
              <div>
                <p>{item}</p>
                <p>{record.correspondingAuthorAffiliation}</p>
              </div>
          )
        }
      },
      /*{
        title: 'Action',
        name: 'action',
        key: 'action',
        dataIndex: 'action',
        width: 150,
        render: (item, record, indexValue) => {
          return (
              <>
                <Tooltip title='Edit  Admin-User' style={{ marginRight: '20px' }}>
                  <Button
                      shape='circle'
                      className='roundSmallBtn'
                      onClick={e => {
                        this.showEditAdmin(record, indexValue)
                      }}>
                    Edit
                  </Button>
                </Tooltip>

              </>
          )
        }
      }*/

    ]
    return (
      //   <>dd{/* {authorData&&authorData} */}</>
        <PageHeaderWrapper title={'All Authors'}>
          <Card bordered={true}>
            <TableComp
                ref={this.table}
                extraProps={{scroll: {x: 1350}}}
                columns={columns}
                apiRequest={this.loadAuthors}
            />
          </Card>

          {this.state.open ? (
              <EditAuthors
                  visible={this.state.open}
                  editVal={this.state.editVal}
                  indexVal={this.state.indexVal1}
                  onClose={this.onClose}
                  editSuccessfull={() => {
                    this.editSuccessfull()
                  }}
              />
          ) : null}
        </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(allAuthors)
