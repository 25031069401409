import { apiUrl } from '../../../settings'
import { getAllArticalActivities } from '../actions'

export const articleUrl = () => {
  return apiUrl + '/article'
}
export const singleArticleUrl = id => {
  return apiUrl + `/article/${id}`
}
export const allArticleUrl = () => {
  return apiUrl + `/allArticle`
}
export const processPaymentUrl = () => {
  return apiUrl + `/processPayment`
}
export const addReviewerInArticleUrl = () => {
  return apiUrl + `/api/addReviewerInArticle`
}
export const getArticleByIdUrl = () => {
  return apiUrl + `/api/getArticleById`
}
export const getMemberHistoryUrl = () => {
  return apiUrl + `/api/getMemberHistory`
}
export const sendReceiveDateUrl = () => {
  return apiUrl + `/api/sendReceiveDate`
}
export const sendCommentsUrl = () => {
  return apiUrl + `/api/sendComments`
}
export const lastArticleNoUrl = () => {
  return apiUrl + `/api/lastArticleNo`
}

export const editReceiveDateUrl = () => {
  return apiUrl + `/api/editReceiveDate`
}

export const commentsToAuthorUrl = () => {
  return apiUrl + `/api/commentsToAuthor`
}
export const ResendCommentsURL = () => {
  return apiUrl + `/api/resendComments`
}
export const reviewerAwardUrl = () => {
  return apiUrl + `/api/reviewerAward`
}
export const RevisedArticleURL = () => {
  return apiUrl + `/api/revisedArticle`
}
export const ChangeReviewerHistoryUrl = () => {
  return apiUrl + `/api/setHistory`
}
export const ResendArticleUrl = () => {
  return apiUrl + `/api/resendArticle`
}
export const ApproveArticleURL = () => {
  return apiUrl + `/api/approveArticle`
}
export const AcceptanceArticleURL = () => {
  return apiUrl + `/api/acceptanceArticle`
}
export const ComposeArticleURL = () => {
  return apiUrl + `/api/composeArticle`
}
export const GalleyProofArticleURL = () => {
  return apiUrl + `/api/galleyProofArticle`
}
export const DOIArticleURL = () => {
  return apiUrl + `/api/DOIArticle`
}
export const OnlinePublishArticleURL = () => {
  return apiUrl + `/api/OnlinePublish`
}
export const CompletePublishArticleURL = () => {
  return apiUrl + `/api/CompletePublish`
}
export const ReviewerCommentsURL = () => {
  return apiUrl + `/api/ReviewerComments`
}
export const getReviewerPendingArticleURL = () => {
  return apiUrl + `/api/reviewerPendingArticle`
}
export const generateAndDepositXmlUrl = () => {
  return apiUrl + `/api/generateXml`
}
export const viewGeneratedXmlUrl = () => {
  return apiUrl + `/api/viewGeneratedXml`
}
export const getReviewerReviewedArticleURL = () => {
  return apiUrl + `/api/reviewerReviewedArticle`
}
export const articleSearchByIdUrl = () => {
  return apiUrl + `/api/articleSearchById`
}
export const DeleteArticleURL = () => {
  return apiUrl + `/api/deleteArticle`
}
export const WithdrawalArticleURL = () => {
  return apiUrl + `/api/withdrawalArticle`
}
export const DeleteReviewerURL = () => {
  return apiUrl + `/deleteMember`
}
export const ReActivateMemberURL = () => {
  return apiUrl + `/reActivateMember`
}

export const AddDoiArticleUrl = () => {
  return apiUrl + `/api/addDoiArticle`
}

export const UpdateDoiImageUrl = () => {
  return apiUrl + `/api/updateDoiImage`
}

export const addNotesUrl = () => {
  return apiUrl + '/addNotes'
}

export const allNotesUrl = () => {
  return apiUrl + '/allNotes'
}
export const notesOnlineSubmissionUrl = () => {
  return apiUrl + '/notes'
}

export const getAllArticleActivityUrl = () => {
  return apiUrl + '/articleActivity'
}

export const articleNoteCountUrl = () => {
  return apiUrl + '/articleNoteCount'
}
export const getNotesUrl = () => {
  return apiUrl + `/api/getNotes`
}
export const deleteArticleNoteUrl = () => {
  return apiUrl + `/api/deleteArticleNote`
}
export const updateOnlineSubmissionUrl = () => {
  return apiUrl + '/updateOnlineSubmission'
}

export const addBlogUrl = () => {
  return apiUrl + `/addBlog`
}


export const updateCompletePublishArticleURL = () => {
  return apiUrl + `/api/update-completePublish`
}