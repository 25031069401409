import {
  Button,
  Card,
  Col,
  Collapse,
  Form,
  Icon,
  Input,
  Modal,
  notification,
  Popconfirm,
  Row,
  Tooltip
} from 'antd'
import CollapsePanel from 'antd/lib/collapse/CollapsePanel'
import _ from 'lodash'
import moment from 'moment'
import React, { PureComponent } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { connect } from 'react-redux'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import {
  AuthorTitle,
  chooseEmailModalFxn,
  CkEditor2013,
  Countries,
  CustomCkEditor,
  DesignationList,
  Discipline,
  Genders,
  InputBox,
  MemberPositions,
  MemberRatings,
  MemberTypes
} from '../../../components/_utils/appUtils'
import { getPushPathWrapper, getUrlParams } from '../../../routes'
import { apiUrl as apiurl } from '../../../settings'
import MentionInfo from '../../email/mentionInfo'
import { CountriesList, JournalList } from '../../journals/actions'
import { singleReviewer } from '../../reviewer/actions'
import { addMember, checkMemberEmails, getNextReviewerId } from '../actions'
import AddDrawer from './addInformation'
import EditInfo from './editInfo'
import styles from './styles.less'

@Form.create()
class AddArticle extends PureComponent {
  state = {
    edit: false,
    journalListArr: [],
    memberImage: {},
    memberCV: {},
    fileKey: moment(),
    reviewerId: '',
    reviewerIdNumber: '',
    ckeditorKey: moment() + 1,
    reviewerFile: {},
    cvAndBrief: {},
    infoData: []
  }

  constructor(props) {
    super(props)
  }

  resetFormData = () => {
    const { dispatch, form } = this.props
    form.setFieldsValue({
      enterDate: moment(),
      memberType: '',
      journals: [],
      rating: '',
      position: '',
      department: '',
      title: '',
      areaOfInterest: '',
      category: '',
      name: '',
      instituteAffiliatedWith: '',
      gender: '',
      address: '',
      designation: '',
      description: '',
      country: '',
      email: '',
      instituteWebsite: '',
      secondaryEmail: '',
      contactNo: '',
      otherDetails: ''
    })
  }

  onClose = () => {
    this.setState({
      ['open']: false
    })
  }

  handleDelete = key => {
    const newData = this.state.infoData.filter(item => item.menuHeading !== key)
    this.setState({ infoData: newData })
  }

  addDetails = data => {
    console.log(data)
    let cloneData = _.clone(this.state.infoData)
    cloneData.push(data)
    this.setState({
      infoData: cloneData
    })
  }
  onClose1 = () => {
    this.setState({
      ['open1']: false
    })
  }

  updateInfo = row => {
    let cloneData = _.clone(this.state.infoData)
    cloneData[row.index] = row
    this.setState({ infoData: cloneData, open1: false })
  }
  handleSubmit = e => {
    const { dispatch, form } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let { reviewerId, reviewerIdNumber, becomeReviewerId } = this.state

        chooseEmailModalFxn({
          callback: async email => {
            let fd = new FormData()
            valData.reviewerId = reviewerId
            // valData.status = 'accepted'
            valData.description = this.state.description
            valData.infoData = this.state.infoData
            valData.reviewerIdNumber = reviewerIdNumber
            valData.fromEmail = email
            valData.template = 'reviewerRegister'
            valData.educationalBackground = this.state.educationalBackground
            valData.briefInfo = this.state.briefInfo
            valData.academicQualification = this.state.academicQualification
            valData.assignments = this.state.assignments
            valData.workExperience = this.state.workExperience
            valData.awardsRecognition = this.state.awardsRecognition
            valData.innovation = this.state.innovation
            valData.otherResponsibilities = this.state.otherResponsibilities
            valData.publications = this.state.publications
            valData.teaching = this.state.teaching
            valData.miscellaneous = this.state.miscellaneous
            if (becomeReviewerId) {
              valData.becomeReviewerId = becomeReviewerId
            }

            let resp = await dispatch(checkMemberEmails(valData))
            if (resp && resp.success) {
              fd.append('obj', JSON.stringify(valData))
              fd.append('memberImage', this.state.memberImage)
              fd.append('memberCV', this.state.memberCV)
              let { success, message } = await dispatch(addMember(fd))

              if (success) {
                notification.success({
                  message: message
                })
                this.resetFormData()
                this.setState({
                  description: '',
                  briefInfo: '',
                  educationalBackground: '',
                  academicQualification: '',
                  assignments: '',
                  workExperience: '',
                  awardsRecognition: '',
                  innovation: '',
                  otherResponsibilities: '',
                  publications: '',
                  teaching: '',
                  miscellaneous: '',
                  infoData: ''
                })
                setTimeout(() => {
                  this.setState({
                    fileKey: moment(),
                    ckeditorKey: moment() + 1
                  })
                  dispatch(getPushPathWrapper('member.addMember'))
                }, 300)

                this.loadReviewerId()
              }
            }
          }
        })
      }
    })
  }

  showDrawer = () => {
    this.setState({
      ['open']: true
    })
  }

  showDrawer1 = (item, index) => {
    this.setState({
      editInfo: { ...item, index },
      ['open1']: true
    })
  }

  setFormValuesFxn = async id => {
    const { dispatch, form } = this.props
    let { error, data } = await dispatch(singleReviewer(id))
    if (data.reviewerFile != null && data.cvAndBrief != null) {
      this.setState({
        memberImage: data.reviewerFile,
        memberCV: data.cvAndBrief
      })
    }
    // if (data.memberImage != null && data.memberCV != null) {
    //   this.setState({ memberImage: data.memberImage, memberCV: data.memberCV })
    // }
    if (error) {
      notification.error({
        message: 'Error Loading Data'
      })
    } else {
      if (data.status && data.status !== 'Accepted') {
        form.setFieldsValue({
          ...data,
          instituteAffiliatedWith: data.institute
        })
        this.setState({ becomeReviewerId: data._id })
      } else {
        Modal.error({
          content: (
            <div>
              <h3>Reviewer already Approved</h3>
            </div>
          ),
          onOk: () => {
            dispatch(getPushPathWrapper('member.addMember'))
          },
          onCancel: () => {
            dispatch(getPushPathWrapper('member.addMember'))
          }
        })
      }
    }
  }

  componentDidMount() {
    this.loadJournalList()
    this.loadReviewerId()
    let data = getUrlParams('member.reviewer', this.props.pathname)
    // let data = getUrlParams('member.reviewer', this.props.pathname)
    if (!!data) {
      let { id } = data
      this.setFormValuesFxn(id)
    }
  }

  loadJournalList = async () => {
    const { dispatch, form } = this.props
    let { data } = await JournalList()
    this.setState({ journalListArr: data })
    form.setFieldsValue({ enterDate: moment() })
  }

  loadReviewerId = async () => {
    const { dispatch } = this.props
    let resp = await dispatch(getNextReviewerId())
    this.setState({
      reviewerIdNumber: resp.reviewerIdNumber,
      reviewerId: resp.reviewerId
    })
  }

  chooseDocument = e => {
    let { name, files } = e.target
    if (files && files.length) {
      this.setState({ [name]: files[0] })
    }
  }

  render() {
    // }

    const {
      form: { getFieldDecorator, getFieldValue, setFieldsValue },
      loading
    } = this.props
    const { edit, journalListArr } = this.state

    const formProps = {
      getFieldDecorator,
      getFieldValue,
      apiurl,
      loading
    }
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      }
    }

    const inputSchema = {
      fields: [
        {
          label: 'Date of Enter',
          key: 'enterDate',
          required: true,
          placeholder: 'Date of Enter',
          type: 'date',
          disabled: true
        },

        {
          label: 'Member Id',
          customField: (
            <InputBox title={`Member Id`} required={true}>
              <Input
                className={'form-control'}
                value={this.state.reviewerId}
                disabled={true}
                onChange={e => {
                  this.setState({ reviewerId: e.target.value })
                }}
              />
            </InputBox>
          )
        },
        {
          label: 'Member Type',
          key: 'memberType',
          required: true,
          placeholder: 'Member Type',
          type: 'select',
          options: MemberTypes,
          onChange: val => {
            setFieldsValue({ memberType: val })
          }
        },
        {
          label: 'Journal of Interest',
          key: 'journals',
          required: true,
          showSearch: true,
          options: journalListArr,
          keyAccessor: x => x.name,
          valueAccessor: x => x.name,
          placeholder: 'Journal',
          type: 'select',
          mode: 'multiple',
          onChange: val => {
            setFieldsValue({ journals: val })
          }
        },
        {
          label: 'Member Position',
          key: 'position',
          placeholder: 'Member Position',
          type: 'select',
          hidden: !(getFieldValue('memberType') === 'Editorial Board'),
          required: getFieldValue('memberType') === 'Editorial Board',
          options: MemberPositions,
          onChange: val => {
            setFieldsValue({ position: val })
          }
        },
        {
          label: 'Rating',
          key: 'rating',
          required: true,
          placeholder: 'Rating',
          type: 'select',
          options: MemberRatings(),
          keyAccessor: x => x.value,
          valueAccessor: x => x.label,
          onChange: val => {
            setFieldsValue({ rating: val })
          }
        },
        {
          label: 'Discipline',
          key: 'category',
          required: true,
          placeholder: 'Discipline',
          options: Discipline,
          type: 'select',
          onChange: val => {
            setFieldsValue({ category: val })
          }
        },
        {
          label: 'Area Of Interest',
          key: 'areaOfInterest',
          required: true,
          placeholder: 'Area Of Interest'
        },
        {
          label: 'Title',
          key: 'title',
          required: true,
          options: AuthorTitle,
          placeholder: 'Title',
          type: 'select',
          onChange: val => {
            setFieldsValue({ title: val })
          }
        },
        {
          label: 'Department',
          key: 'department',
          required: true,
          placeholder: 'Department'
        },
        {
          label: 'Name',
          key: 'name',
          required: true,
          placeholder: 'Name'
        },
        {
          label: 'Institute Affiliated With',
          key: 'instituteAffiliatedWith',
          required: true,
          placeholder: 'Institute Affiliated With'
        },

        {
          label: 'Gender',
          key: 'gender',
          required: true,
          options: Genders,
          placeholder: 'Gender',
          type: 'select',
          onChange: val => {
            setFieldsValue({ gender: val })
          }
        },
        {
          label: 'Address',
          key: 'address',
          required: true,
          placeholder: 'Address'
        },
        {
          label: 'Designation',
          key: 'designation',
          required: true,
          placeholder: 'Designation',
          options: DesignationList,
          type: 'select',
          onChange: val => {
            setFieldsValue({ designation: val })
          }
        },
        {
          label: 'Country',
          key: 'country',
          required: true,
          showSearch: true,
          options: Countries,
          keyAccessor: x => x.name,
          valueAccessor: x => x.name,
          placeholder: 'Country',
          type: 'select',
          onChange: val => {
            setFieldsValue({ country: val })
          }
        },

        {
          label: 'Primary Email',
          key: 'email',
          required: true,
          placeholder: 'Primary Email',
          type: 'email'
        },
        {
          label: 'Institute Website',
          key: 'institutionWebsite',
          placeholder: 'Institute Website'
        },
        {
          label: 'Secondary Email',
          key: 'secondaryEmail',
          placeholder: 'Secondary Email'
        },
        {
          label: 'Profile Image',
          customField: (
            <InputBox title={`Profile Image`} className={'rowFlex'}>
              <Input
                type={'file'}
                name={'memberImage'}
                id={'memberImage'}
                className={'form-control'}
                key={this.state.fileKey}
                onChange={e => {
                  this.chooseDocument(e)
                }}
              />
              {this.state.memberImage && this.state.memberImage.path ? (
                <a
                  className={'linkBtn'}
                  target={'_blank'}
                  href={this.state.memberImage.path}>
                  <Icon type={'eye'} />
                </a>
              ) : null}
            </InputBox>
          )
        },
        {
          label: 'CV Attachment',
          customField: (
            <InputBox title={`CV Attachment`} className={'rowFlex'}>
              <Input
                type={'file'}
                name={'memberCV'}
                id={'memberCV'}
                className={'form-control'}
                key={this.state.fileKey}
                onChange={e => {
                  this.chooseDocument(e)
                }}
              />
              {this.state.memberCV !== {} && this.state.memberCV.path ? (
                <a
                  className={'linkBtn'}
                  target={'_blank'}
                  href={this.state.memberCV.path}>
                  <Icon type={'eye'} />
                </a>
              ) : null}
            </InputBox>
          )
        },
        {
          label: 'Contact number',
          key: 'contactNo',
          placeholder: 'Contact number'
        },

        {
          label: 'Other Details (if any)',
          key: 'otherDetails',
          placeholder: 'Other Details (if any)',
          type: 'textArea',
          rows: 2,
          span: 24
        },
        {
          label: 'Brief Info',
          key: 'description',
          span: 24,
          customField: (
            <CkEditor2013
              title={'Brief Info'}
              basic={true}
              height={400}
              html={this.state.description}
              onChange={data => {
                this.setState({
                  description: data
                })
              }}
            />
          )
        },
      ]
    }

    return (
      <PageHeaderWrapper title={'Add Member'}>
        <Card bordered={true}>
          <Form
            onSubmit={this.handleSubmit}
            hideRequiredMark
            className={'inputForm'}
            noValidate={true}>
            <Row gutter={16}>
              {inputSchema.fields.map((item, key) => {
                return (
                  !item.hidden && (
                    <Col
                      span={item.span ? item.span : 12}
                      md={item.span ? item.span : 12}
                      sm={12}
                      xs={24}
                      key={key}>
                      {item.customField ? (
                        item.customField
                      ) : (
                        <GetEachFormFields
                          item={item}
                          getFieldDecorator={getFieldDecorator}
                          formItemLayout={formItemLayout}
                        />
                      )}
                    </Col>
                  )
                )
              })}
            </Row>
            <Card
              title={'Information'}
              className={'p10'}
              extra={
                <Form.Item
                  name='add'
                  style={{ marginLeft: '10%', marginTop: '8px' }}>
                  <a
                    onClick={this.showDrawer}
                    className='ant-btn ant-btn-primary'>
                    Add Information
                  </a>
                </Form.Item>
              }>
              <Col style={{ marginTop: '8px' }} className={'white_collapse'}>
                {this.state.infoData &&
                this.state.infoData.map((item, i) => {
                  return (
                    <>
                      <div key={i} style={{ marginTop: '18px' }}>
                        <Collapse style={{ backgroundColor: 'white' }}>
                          <CollapsePanel
                            header={`${item.title} (${item.menuHeading})`}
                            extra={
                              <h1>
                                <Tooltip
                                  title='Edit  information'
                                  style={{ marginLeft: '20px' }}>
                                  <Button
                                    style={{ border: 20 }}
                                    shape='circle'
                                    onClick={() => this.showDrawer1(item, i)}
                                    icon={'edit'}
                                  />
                                </Tooltip>
                                <Popconfirm
                                  placement='topLeft'
                                  title={`Are you Sure, You want to delete?`}
                                  onConfirm={() =>
                                    this.handleDelete(item.menuHeading)
                                  }
                                  okText='Yes'
                                  cancelText='No'>
                                  <Button
                                    style={{ border: 20, marginLeft: 10 }}
                                    shape='circle'
                                    icon={'delete'}
                                  />
                                </Popconfirm>
                              </h1>
                            }>
                            <p>{ReactHtmlParser(item.description)}</p>
                          </CollapsePanel>
                        </Collapse>
                      </div>
                    </>
                  )
                })}
              </Col>
            </Card>

            {this.state.open && (
              <AddDrawer
                addDetails={this.addDetails}
                visible={this.state.open}
                onClose={() => {
                  this.onClose()
                }}
                arrayData={this.state.infoData}
              />
            )}

            {this.state.editInfo && this.state.open1 ? (
              <EditInfo
                arrayData={this.state.infoData}
                visible={this.state.open1}
                editInfo={this.state.editInfo}
                updateInfo={valData => {
                  this.updateInfo(valData)
                }}
                onClose={() => {
                  this.onClose1()
                }}
              />
            ) : null}

            <Form.Item style={{ marginTop: 32 }}>
              <Button type="primary" htmlType="submit">
                SUBMIT
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search,
  pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddArticle)
