import _ from 'lodash'
import moment from 'moment'
import countryJson from '../../assets/jsonFiles/countries'
import { apiUrl } from '../../settings'

export { default as InputBox } from './InputBox'
export { default as ChooseEmailModal } from './chooseEmailModal'
export { default as chooseEmailModalFxn } from './chooseEmailModalFxn'
export { default as CustomCkEditor } from './ckEditor'
export { default as CkEditor2013 } from './ckEditor2013'
export { default as FullCkEditor } from './fullCkEditor'
export { default as NotificationBox } from './notificationBox'

export const DefaultTablePagination = (
  newParams,
  defaultPageSize = 10,
  pageSizeOptions = ['50', '100', '200', '500']
) => {
  let params = { defaultPageSize, pageSizeOptions, ...newParams }
  return params
}


export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 }
  }
}

export const DateFormat = 'DD-MMM-YYYY'
export const filterDateFormat = 'MM-DD-YYYY'

export const DisplayDate = date => {
  if (date) {
    return moment(date).format(DateFormat)
  } else {
    return null
  }
}

export const displayDateWithtime = date => {
  if (date) {
    return moment(date).format('DD.MM.YYYY,h:mm A')
  } else {
    return null
  }
}
export const ProcessList = [
  'Normal Review Process',
  'Rapid Online Publication',
  'Rapid Complete Publication'
]

export const ArticleTypeList = [
  'Full Research Article',
  'Review Article',
  'Short Communication'
]

export const DesignationList = [
  'Principal',
  'Additional Director',
  'Assistant Director',
  'Acting Director',
  'Chairman',
  'Ex-Vice Chancellor',
  'Former Dean',
  'Former Director',
  'Student MSc',
  'Student PhD',
  'Asst. Professor',
  'Professor',
  'Associate Professor',
  'Scientist',
  'Jr. Scientist',
  'Sr. Scientist',
  'Pr. Scientist',
  'HOD',
  'Retd.',
  'Dean',
  'Director',
  'Vice Chancellor',
  'Others'
]
export const AuthorTitle = ['Dr.', 'Dr.(Mrs.)', 'Prof.', 'Prof. Dr.']

export const Countries = [...countryJson]

export const MemberTypes = [
  'Reviewer',
  'Editorial Board',

  'Executive Editorial Board'
]
export const MemberPositions = [
  'Chief Editor',
  'Associate chief editor',
  'Editor',
  'Member',
  'Executive Editorial Board'
]
export const MemberRatings = () => {
  let ratingList = []
  for (let i = 0; i <= 10; i++) {
    ratingList.push({ label: `${i} star`, value: `${i}` })
  }
  ratingList.push({ label: `10+ star`, value: `10+` })
  return ratingList
}

export const Genders = ['Male', 'Female']
export const Discipline = [
  'Agricultural Science',
  'Veterinary Science',
  'Dairy Science',
  'Food Science'
]
export const DisciplineFilter = [
  'Agricultural Science',
  'Veterinary Science',
  'Dairy Science',
  'Food Science',
  'Any',
  'Both'
]

export const mentionsFeeds = [
  'articleType',
  'correspondingAuthor',
  'correspondingAuthorsEmail',
  'manuScriptTitle',
  'title',
  'name',
  'journal',
  'abstract',
  'volume',
  'issues',
  // 'articleTitle',
  'authorName',
  'authorAddress',
  'doi',
  'articleOrder',
  'articleNo',
  'publishDate',
  'keywords',
  'email',
  'references',
  'isPublished',
  'articleFile',
  'correspondingAuthor',
  'correspondingAuthorContact',
  'correspondingAuthorAddress',
  'correspondingAuthorCountry',
  'correspondingAuthorAffiliation',
  'correspondingAuthorDesignation',
  'printIssn',
  'onlineIssn',
  'ranking',
  'factor',
  'frequency',
  'rating',
  'orderNumber',
  'publication',
  'description',
  'subjects',
  'slugJournal',
  'journalFile',
  'volume',
  'issues',
  'currency',
  'amount',
  'modeOfPayment',
  'reviewerId',
  'userName',
  'manageSchedule',
  //'manageIndianSchedule',
  //'manageForeignSchedule',
  'anotherJournalName',
  'processingCharges',
  'otherAuthorName',
  'earlyComments',
  'specialNote',
  'approvalSpecialNote',
  'approvalAmount',
  'acceptedArticlePayment',
  'publicationCharges',
  'onlineSubmission',
  'onlineSubmissionReviewer',
  'approvalType',
  'acceptedArticlePaymentMode',
  'articleLink',
  'sendDate',
  'reminderDate',
  'receiveDate',
  'commentsDate',
  'webCommentsDate',
  'sendToAuthorDate',
  'revisedArticleDate',
  'approvedArticleDate',
  'acceptedArticlePaymentDate',
  'sendgalleyProofDate',
  'doiProcessDate',
  'onlinePublishedDate',
  'sendForPublicationDate',
  'withdrawnDate',
  'reminderDate',
  'acknowledgmentDate',
  'finalReminderDate',
  'reminderDateCommentsToAuthor',
  'approvalReminderDate',
  'galleyReminderDate'
]

export const ArticleStatus = [
  { status: 'Submitted Article' },
  { status: 'Under Process' },
  { status: 'Under Reviewer' },
  { status: 'Reviewed Comments' },
  { status: 'Comments To Author' },
  { status: 'Revised Article' },
  { status: 'Approved Article' },
  { status: 'Accepted Article' },
  { status: 'Composing Article' },
  { status: 'Galley Proof' },
  { status: 'DOI' },
  { status: 'Online Publish' },
  { status: 'For Print Publication' },
  {
    status: 'Published Article',
    label: 'Deleted Article',
    dateField: 'submittedDate'
  },
  { status: 'Deleted' },
  { status: 'Withdrawled' }
]

export const GetDownloadLink = link => {
  return `${apiUrl}${link}`
}

export const PaymentModes = [
  // 'CC Avenue',
  'Online Payment',
  'NEFT',
  'Cash',
  'Draft or Cheque',
  'PayPal',
  'Others'
]
export const MemberStatus = [
  'Available',
  'Not Available'
  // 'Deleted'
]
export const MemberStatusNew = ['Available', 'Not Available', 'Deleted']

export const MonthArr = [
  {
    name: 'January',
    value: 1
  },
  {
    name: 'February',
    value: 2
  },
  {
    name: 'March',
    value: 3
  },
  {
    name: 'April',
    value: 4
  },
  {
    name: 'May',
    value: 5
  },
  {
    name: 'June',
    value: 6
  },
  {
    name: 'July',
    value: 7
  },
  {
    name: 'August',
    value: 8
  },
  {
    name: 'September',
    value: 9
  },
  {
    name: 'October',
    value: 10
  },
  {
    name: 'November',
    value: 11
  },
  {
    name: 'December',
    value: 12
  }
]

export const YearArr = () => {
  // let currentYear = moment().year()
  let toYear = 2024
  let years = []
  for (let i = 2013; i <= toYear; i++) {
    years.push(i)
  }
  return years
}

export const DateByMonth = month => {
  let year = moment().year()
  return moment(month + '-' + 1 + '-' + year, filterDateFormat)
}

export const DateByYear = year => {
  return moment(1 + '-' + 1 + '-' + year, filterDateFormat)
}

export const DateByMonthAndYear = (month, year) => {
  return moment(month + '-' + 1 + '-' + year, filterDateFormat)
}

export const TemplateTitleList = [
  'requestToNewReviewer',
  'processingChargesAcknowledgement',
  'acknowledgementWithCharges',
  'acknowledgementWithoutCharges',
  'reviewerRegister',
  'reviewerThanksAfterReceivingComments'
]

export const RemarksList = [
  'Not his field',
  'Received from another reviewer',
  'Withdraw by author',
  'Not Reviewer field',
  'Do not have time right now',
  'Other text box'
]

export const currencyList = ['Rs.', 'USD']

export const AssignToName = ['Neha', 'Sonam', 'Bindu', 'Divya', 'Komal']

export const getSlugUrl = originalName => {
  return originalName
    .replace(/[^\w\s-]/g, '')
    .trim()
    .toLowerCase()
    .replace(/[-\s]+/g, '-')
}

export const getFigureContent = html => {
  let defaultEt = '<em>et_al</em>'
  html = html
    .replace(/ <em>et al., <\/em>/gi, defaultEt)
    .replace(/ <em>et al.,<\/em> /gi, defaultEt)
    .replace(/ <em>et al.<\/em>, /gi, defaultEt)
    .replace(/ <em>et al<\/em>. /gi, defaultEt)
    .replace(/ <em>et al<\/em>., /gi, defaultEt)

  let allEl = []
  let splitHtml = html.split(' ')
  _.each(splitHtml, item => {
    if (item.indexOf('et_al') > 0) {
      if (item.indexOf('.') > 0) {
        item = item.split('.')[0] + '.'
      }
      if (item.indexOf(';') > 0) {
        item = item.split(';')[0] + ';'
      }
      allEl.push(item)
    }
  })
  _.each(allEl, (item, key) => {
    let idTag = item.replace('<em>et_al</em>', '_').replace(/[^\w\s]/gi, '')
    let idLink = idTag.match(/([a-zA-Z0-9._-]+_+[0-9]{4})/g)
    if (idLink && idLink.length) {
      idLink = idLink[0].toLowerCase()
    }
    let tagValue = item.replace('<em>et_al</em>', ' <em>et al</em>., ')
    let hyperText = `<span><a href='#${idLink}'>${tagValue}</a></span>`
    html = html.replace(item, hyperText)
  })

  return html
}
